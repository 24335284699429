@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
* {
  font-family: 'Roboto Slab', serif;
}
@media only screen and (orientation: landscape) {
  #turn {
    display: block;
  }
  #container {
    display: none;
  }
}
@media only screen and (orientation: portrait) {
  #turn {
    display: none;
  }
  #container {
    display: block;
  }
}

html {
  scroll-behavior: smooth;
}

.app {
  padding: 0 10px;
}

@media screen and (max-width: 600px) {
  .app {
    padding: 0 5px;
    background: #fff;
  }
}

.sem-handler .ant-input-number-handler-wrap {
  display:none;
}