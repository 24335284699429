.nao-encontrado__component {
  background-color: rgba(255, 255, 255, 1);
}

.nao-encontrado__container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: calc(100vw - 10px);
}

.nao-encontrado__sub-title {
  font-size: 1rem;
}
