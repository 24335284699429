@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
.app {
  padding: 20px;
}

* {
  font-family: 'Roboto Slab', serif;
}
@media only screen and (orientation: landscape) {
  #turn {
    display: block;
  }
  #container {
    display: none;
  }
}
@media only screen and (orientation: portrait) {
  #turn {
    display: none;
  }
  #container {
    display: block;
  }
}

html {
  scroll-behavior: smooth;
}

.app {
  padding: 0 10px;
}

@media screen and (max-width: 600px) {
  .app {
    padding: 0 5px;
    background: #fff;
  }
}

.sem-handler .ant-input-number-handler-wrap {
  display:none;
}
/* .login-form {
  margin: 16vh auto 0;
  max-width: 300px;
} */

body {
  background-image: url(/static/media/fundo.e76370da.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.form {
  background-color: rgb(251, 251, 251);
  position: absolute;
  top: 35%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  /*height: 400px;*/
  padding: 36px;
  /* box-shadow: 0 0 100px */
  box-shadow: 0px 0px 30px 1px;
}
.form-email {
  background-color: rgb(251, 251, 251);
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 300px;
  padding: 36px;
  /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.08); */
  box-shadow: 0px 0px 30px 1px;
}

.form-cadastro {
  background-color: rgb(251, 251, 251);
  position: absolute;
  top: 35%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 500px;
  padding: 36px;
  /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.08); */
  box-shadow: 0px 0px 30px 1px;
}

.form .p {
  color: rgb(204, 204, 204);
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  /* width: 40px; */
  margin-right: 8px;
  max-width: 50% !important;
}

.logo .span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  left: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
}

.btn-google {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee !important;
}

.btn-google div {
  background: rgba(255, 255, 255, 0) !important;
  margin-top: 5px;
  margin-right: 2px !important;
}

.preco-oferta-produto {
  display: flex;
  align-items: baseline;
}

.delivery-p-oferta {
  display: flex;
  justify-content: start;
  align-items: baseline;
}

.delivery-p-oferta span {
  margin-right: 3px;
  margin-left: 3px;
}

.delivery-p-oferta-tag {
  margin: 0;
}

[data-theme='compact']
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: rgb(254, 254, 254);
  border-radius: 2px;
  margin-bottom: 0px;
  border: 0px;
  overflow: hidden;
}
@media (max-width: 960px) {
  .ant-page-header-heading-left {
    justify-content: center;
    z-index: 1;
  }
  .ant-page-header-compact {
    background-color: #fff;
  }
  .ant-afix {
    opacity: 0;
    transition: opacity 2s ease-in;
  }
  /*  .ant-card-meta-detail {
    max-width: 195px;
  }*/
}

@media (min-width: 960px) {
  .teste:target::before {
    content: '';
    display: block;
    height: 20px; /* fixed header height*/
    margin: -20px 0 0; /* negative fixed header height */
  }
}

.menu-scroll {
  overflow-x: scroll;
  margin: 0;
}

.menu-scroll::-webkit-scrollbar {
  display: none;
}
.menu-scroll {
  -ms-overflow-style: none;
}

.ant-card-meta-title {
  white-space: unset !important;
}

.valor-ajuste-feedback__container {
  padding: 0.5rem 0;
}

.preco-normal {
  color: green;
}

.opcao-disabled,
.opcao-disabled .ant-card-meta-title,
.opcao-disabled .ant-card-meta-description ,
.opcao-disabled .preco-normal {
  color: gray;
}
.pedido-detalhe {
    color: red;
    font-size: 13px;
}

.timeline-background {
    background-color: white;
    padding: 15px;
}

.p-status {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2px;
}

.p-valor {
    color: gray;
    font-size: 13px;
    margin-top: 0px;
}

.detalhe-container {
    display: flex;
    width: 100%;
}

.detalhe-left {
    flex-grow: 1;
}

.detalhe-right {
    flex-shrink: 1;
    text-align: right;
}
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

.main-header {
  background: #fcfcfc;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  /* height: 104px; */
}

.mh-logo {
  max-width: 275px;
  margin-right: 0px;
}

.mh-logo img {
  max-width: 75% !important;
  width: 150px;
  padding: 5px;
}
@media (max-width: 960px) {
  .mh-logo img {
    max-width: 60% !important;
    width: 150px;
    padding: 5px;
  }
}
.site-layout-content {
  background: rgba(240, 240, 240);
  padding: 16px;
  min-height: 500px;
}

img {
  max-width: 100%;
  height: auto;
}
.container {
  width: 1204px;
  max-width: 100%;
  padding: 10px;
  margin: 0 auto;
}
.main-header .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.ant-layout-header {
  padding: 0px;
}

.ant-layout-content {
  background-color: rgba(240, 240, 240) !important;
  padding: 0 10px !important;
}
.ant-card-bordered {
  border: 1px solid #ddd;
}

@media (min-width: 960px) {
  .main-header {
    height: 94px;
    width: 100%;
  }
  .main-header .container {
    justify-content: space-between;
  }
}
/* --------mobile------- */
@media (max-width: 960px) {
  .main-header {
    height: 84px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .main-header .container {
    padding: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  height: 60px;
  left: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  border-top: 1px solid #f0f0f0;
}

.footer-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 60px;
  left: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  border-top: 1px solid #f0f0f0;
}

.layout {
  margin-bottom: 60px;
}

.ant-layout-content {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.botao-localizacao {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.ripple {
  padding: 10px;
  border-radius: 20px;
  background-position: center;
  transition: background 0.6s;
}
.ripple:hover {
  background: #fff radial-gradient(circle, transparent 1%, #fff 1%)
    center/15000%;
}
.ripple:active {
  background-color: #a10c0c;
  background-size: 100%;
  transition: background 0s;
}

.ant-spin {
  position: fixed !important;
  max-height: unset !important;
}

.ant-spin {
  position: fixed !important;
  max-height: unset !important;
}

.activeCategoryLink {
  color: #ed1c24;
  /* font-weight: 600; */
}
ul a {
  color: #000000;
}

.lgpd-banner {
  align-items: baseline;
  background-color: #ed1c24;
  display: flex;
  flex-direction: column;
  font-family: sans-serif !important;
  left: 0;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 99;
}

.lgpd-banner-description {
  padding: 0 10px 10px;
}

.lgpd-banner-action {
  padding: 0 10px 10px;
  text-align: center;
  width: 100%;
}

.lgpd-banner-description-text {
  color: white;
  font-weight: bold;
  margin: 0;
  padding: 10px;
}

.loading {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body {
  display: none;
  background-image: none;
}
.nao-encontrado__component {
  background-color: rgba(255, 255, 255, 1);
}

.nao-encontrado__container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: calc(100vw - 10px);
}

.nao-encontrado__sub-title {
  font-size: 1rem;
}

