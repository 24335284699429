/* .login-form {
  margin: 16vh auto 0;
  max-width: 300px;
} */

body {
  background-image: url('../../assets/fundo.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.form {
  background-color: rgb(251, 251, 251);
  position: absolute;
  top: 35%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  /*height: 400px;*/
  padding: 36px;
  /* box-shadow: 0 0 100px */
  box-shadow: 0px 0px 30px 1px;
}
.form-email {
  background-color: rgb(251, 251, 251);
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 300px;
  padding: 36px;
  /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.08); */
  box-shadow: 0px 0px 30px 1px;
}

.form-cadastro {
  background-color: rgb(251, 251, 251);
  position: absolute;
  top: 35%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  height: 500px;
  padding: 36px;
  /* box-shadow: 0 0 100px rgba(0, 0, 0, 0.08); */
  box-shadow: 0px 0px 30px 1px;
}

.form .p {
  color: rgb(204, 204, 204);
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  /* width: 40px; */
  margin-right: 8px;
  max-width: 50% !important;
}

.logo .span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  left: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
}

.btn-google {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee !important;
}

.btn-google div {
  background: rgba(255, 255, 255, 0) !important;
  margin-top: 5px;
  margin-right: 2px !important;
}
