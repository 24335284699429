.lgpd-banner {
  align-items: baseline;
  background-color: #ed1c24;
  display: flex;
  flex-direction: column;
  font-family: sans-serif !important;
  left: 0;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 99;
}

.lgpd-banner-description {
  padding: 0 10px 10px;
}

.lgpd-banner-action {
  padding: 0 10px 10px;
  text-align: center;
  width: 100%;
}

.lgpd-banner-description-text {
  color: white;
  font-weight: bold;
  margin: 0;
  padding: 10px;
}
