.loading {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body {
  display: none;
  background-image: none;
}