.pedido-detalhe {
    color: red;
    font-size: 13px;
}

.timeline-background {
    background-color: white;
    padding: 15px;
}

.p-status {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2px;
}

.p-valor {
    color: gray;
    font-size: 13px;
    margin-top: 0px;
}

.detalhe-container {
    display: flex;
    width: 100%;
}

.detalhe-left {
    flex-grow: 1;
}

.detalhe-right {
    flex-shrink: 1;
    text-align: right;
}