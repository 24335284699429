.delivery-p-oferta {
  display: flex;
  justify-content: start;
  align-items: baseline;
}

.delivery-p-oferta span {
  margin-right: 3px;
  margin-left: 3px;
}

.delivery-p-oferta-tag {
  margin: 0;
}

[data-theme='compact']
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: rgb(254, 254, 254);
  border-radius: 2px;
  margin-bottom: 0px;
  border: 0px;
  overflow: hidden;
}
@media (max-width: 960px) {
  .ant-page-header-heading-left {
    justify-content: center;
    z-index: 1;
  }
  .ant-page-header-compact {
    background-color: #fff;
  }
  .ant-afix {
    opacity: 0;
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
  }
  /*  .ant-card-meta-detail {
    max-width: 195px;
  }*/
}

@media (min-width: 960px) {
  .teste:target::before {
    content: '';
    display: block;
    height: 20px; /* fixed header height*/
    margin: -20px 0 0; /* negative fixed header height */
  }
}

.menu-scroll {
  overflow-x: scroll;
  margin: 0;
}

.menu-scroll::-webkit-scrollbar {
  display: none;
}
.menu-scroll {
  -ms-overflow-style: none;
}

.ant-card-meta-title {
  white-space: unset !important;
}
