* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

.main-header {
  background: #fcfcfc;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  /* height: 104px; */
}

.mh-logo {
  max-width: 275px;
  margin-right: 0px;
}

.mh-logo img {
  max-width: 75% !important;
  width: 150px;
  padding: 5px;
}
@media (max-width: 960px) {
  .mh-logo img {
    max-width: 60% !important;
    width: 150px;
    padding: 5px;
  }
}
.site-layout-content {
  background: rgba(240, 240, 240);
  padding: 16px;
  min-height: 500px;
}

img {
  max-width: 100%;
  height: auto;
}
.container {
  width: 1204px;
  max-width: 100%;
  padding: 10px;
  margin: 0 auto;
}
.main-header .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.ant-layout-header {
  padding: 0px;
}

.ant-layout-content {
  background-color: rgba(240, 240, 240) !important;
  padding: 0 10px !important;
}
.ant-card-bordered {
  border: 1px solid #ddd;
}

@media (min-width: 960px) {
  .main-header {
    height: 94px;
    width: 100%;
  }
  .main-header .container {
    justify-content: space-between;
  }
}
/* --------mobile------- */
@media (max-width: 960px) {
  .main-header {
    height: 84px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .main-header .container {
    padding: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  height: 60px;
  left: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  border-top: 1px solid #f0f0f0;
}

.footer-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 60px;
  left: 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  border-top: 1px solid #f0f0f0;
}

.layout {
  margin-bottom: 60px;
}

.ant-layout-content {
  min-height: fit-content;
}

.botao-localizacao {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.ripple {
  padding: 10px;
  border-radius: 20px;
  background-position: center;
  transition: background 0.6s;
}
.ripple:hover {
  background: #fff radial-gradient(circle, transparent 1%, #fff 1%)
    center/15000%;
}
.ripple:active {
  background-color: #a10c0c;
  background-size: 100%;
  transition: background 0s;
}

.ant-spin {
  position: fixed !important;
  max-height: unset !important;
}

.ant-spin {
  position: fixed !important;
  max-height: unset !important;
}

.activeCategoryLink {
  color: #ed1c24;
  /* font-weight: 600; */
}
ul a {
  color: #000000;
}
